import _isExtendable from "is-extendable";
var exports = {};
var isObject = _isExtendable;

exports = function extend(o
/*, objects*/
) {
  if (!isObject(o)) {
    o = {};
  }

  var len = arguments.length;

  for (var i = 1; i < len; i++) {
    var obj = arguments[i];

    if (isObject(obj)) {
      assign(o, obj);
    }
  }

  return o;
};

function assign(a, b) {
  for (var key in b) {
    if (hasOwn(b, key)) {
      a[key] = b[key];
    }
  }
}
/**
 * Returns true if the given `key` is an own property of `obj`.
 */


function hasOwn(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export default exports;